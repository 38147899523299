<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <div class="main-box">
            <div class="title-box">
                <div class="title-icon"></div>
                <div class="title-text">基础信息</div>
            </div>

            <el-descriptions title="" :column="2">
                <el-descriptions-item label="车牌号">{{
                    ajaxParam.carNum
                }}</el-descriptions-item>
                <el-descriptions-item label="车辆自编号">{{
                    ajaxParam.carNo
                }}</el-descriptions-item>
                <el-descriptions-item label="车型">{{
                    ajaxParam.carModelName
                }}</el-descriptions-item>
                <el-descriptions-item label="维修类型">{{
                    ajaxParam.maintenanceTypeStr
                }}</el-descriptions-item>
                <el-descriptions-item label="承修单位">{{
                    ajaxParam.maintenanceUnit
                }}</el-descriptions-item>
                <el-descriptions-item label="进厂时间">{{
                    ajaxParam.arrivalTime
                }}</el-descriptions-item>
                <el-descriptions-item label="出厂时间">{{
                    ajaxParam.deliveryTime
                }}</el-descriptions-item>
                <el-descriptions-item label="行驶里程"
                    >{{ ajaxParam.drivingMileage }}公里</el-descriptions-item
                >
            </el-descriptions>

            <div class="title-box" style="margin-top: 16px">
                <div class="title-icon"></div>
                <div class="title-text">维修内容</div>
            </div>

            <div
                v-for="(item, index) in ajaxParam.carMaintenanceContentDtos"
                :key="index"
            >
                <el-descriptions title="" :column="1">
                    <el-descriptions-item label="维修项目">{{
                        item.maintenanceProjectStr
                    }}</el-descriptions-item>
                    <el-descriptions-item label="维修费用"
                        >{{ item.maintenanceCosts }}元</el-descriptions-item
                    >
                    <el-descriptions-item label="状况">{{
                        item.state
                    }}</el-descriptions-item>
                    <el-descriptions-item label="附件">
                        <div class="xxxxx">
                            <a
                                v-for="(file, index) in item.fileVos"
                                :key="index"
                                :href="`${fileUrl}/${file.filePath}`"
                                :download="file.fileName"
                                style="color: #487ee0; margin-bottom: 6px"
                            >
                                {{ file.fileName }}
                            </a>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { fileUrl } from "@/api/Global";
export default {
    title: "查看数据",
    name: "LookData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },
    data() {
        return {
            fileUrl,
            ajaxParam: {
                companyId: "", // 所属公司
                companyName: "",
                carId: "", // 车辆Id
                carNum: "",
                maintenanceType: "", // 维修类型
                maintenanceTypeStr: "",
                maintenanceUnit: "", // 维修单位（最长50）
                arrivalTime: "", // 进厂时间
                deliveryTime: "", // 出厂时间
                drivingMileage: "", // 行驶里程（最大9999999）
                carModelName: "", // 车型
                carNo: "", // 车辆自编号
                carMaintenanceContentDtos: [
                    {
                        id: "",
                        maintenanceProject: "", // 维修项目
                        maintenanceCosts: "", // 维修费用
                        state: "", // 状况
                        filePaths: [],
                    },
                ],
            },
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/carMaintenanceRecords/${this.op.id}`
            );
            if (code == 200) {
                // this.ajaxParam = data;
                Object.keys(this.ajaxParam).forEach((key) => {
                    this.ajaxParam[key] = data[key];
                });
                this.ajaxParam.carMaintenanceContentDtos = [];
                Array.isArray(data.carMaintenanceContentVos) &&
                    data.carMaintenanceContentVos.forEach((item) => {
                        this.ajaxParam.carMaintenanceContentDtos.push(item);
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.title-box {
    margin-bottom: 16px;
    // height: 50px;
    display: flex;
    align-items: center;

    .title-icon {
        width: 5px;
        height: 16px;
        background: rgb(72, 152, 226);
        margin-right: 10px;
    }
}

.base-info-box {
    padding: 16px;
    background: #c8c8cd3d;
    border-radius: 10px;
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.main-box {
    margin-left: 40px;
}
.info-item {
    width: 200px;
    display: flex;
    margin: 10px 0;
}
.info-item-2 {
    display: flex;
    margin: 10px 0;
}
.info-label {
    min-width: 80px;
}
.content-box {
    padding: 16px;
    background: #c8c8cd3d;
    border-radius: 10px;
    margin: 10px 0;
}
.xxxxx {
    display: flex;
    flex-direction: column;
}
</style>
