<template>
    <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="维修计划" name="first">
            <repairPlan></repairPlan>
        </el-tab-pane>
        <el-tab-pane label="维修记录" name="second">
            <repairRecord></repairRecord>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import repairPlan from "./repairPlan/HomeView";
import repairRecord from "./repairRecord/HomeView";
export default {
    components: { repairPlan, repairRecord },
    data() {
        return {
            activeName: "first",
        };
    },
};
</script>

<style lang="scss" scoped></style>
