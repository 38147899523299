import { charMax, requiredInput, requiredSelect, priceFn } from "@/rules";
export const rules = {
    companyId: [requiredSelect()],
    carId: [requiredSelect()],
    maintenanceType: [requiredSelect()],
    maintenanceUnit: [requiredInput(), charMax(50)],
    arrivalTime: [requiredSelect()],
    deliveryTime: [requiredSelect()],
    drivingMileage: [requiredInput()],
    maintenanceProject: [requiredSelect()],
    maintenanceCosts: [requiredSelect(), priceFn()],
    state: [requiredSelect(), charMax(200)],
    fileVos: [requiredSelect()],
};
