<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            size="mini"
            ref="refElForm"
            label-width="120px"
        >
            <el-form-item :label="`${'计划名称'}:`">
                {{ ajaxParam.planName }}
            </el-form-item>
            <el-form-item :label="`${'维修时间'}:`">
                {{ ajaxParam.maintenanceTime }}
            </el-form-item>
            <el-form-item :label="`${'维修车辆'}:`">
                {{ ajaxParam.carNums }}
            </el-form-item>
            <el-form-item :label="`${'计划状态'}:`">
                <div
                    :style="
                        ajaxParam.status === '0'
                            ? `color: rgb(12, 184, 128)`
                            : ``
                    "
                >
                    {{ ajaxParam.statusStr }}
                </div>
            </el-form-item>
            <el-form-item :label="`${'维修提醒'}:`">
                <div
                    :style="
                        ajaxParam.maintenancePlanExpire == '0'
                            ? `color:#0f50ec`
                            : `color: #DB1A1A`
                    "
                >
                    {{ ajaxParam.maintenanceReminder }}
                </div>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button
                type="success"
                size="small"
                @click="onOpea"
                v-if="ajaxParam.status === '1'"
                >开始计划</el-button
            >
            <el-button type="danger" size="small" @click="onOpea" v-else
                >终止计划</el-button
            >
            <el-button type="primary" size="small" @click="onUpdate"
                >更新计划</el-button
            >
        </div>
    </el-dialog>
</template>

<script>
export default {
    title: "查看数据",
    name: "DealData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },
    data() {
        return {
            carArr: [],
            ajaxParam: {
                companyName: "",
                companyId: "", // 所属公司
                planName: "", // 计划名称
                maintenanceTime: "", // 维修时间
                carNums: "", // 车牌号码用逗号隔开
                maintenanceReminder: "",
                maintenancePlanExpire: "",
                statusStr: "",
                status: "",
            },
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/carMaintenancePlan/${this.op.id}`
            );
            if (code == 200) {
                // this.ajaxParam = data;
                Object.keys(this.ajaxParam).forEach((key) => {
                    this.ajaxParam[key] = data[key];
                });
            }
        },
        onUpdate() {
            this.$emit("onUpdate", this.op.id);
        },
        onOpea() {
            this.$emit("onOpea", {
                id: this.op.id,
                status: this.ajaxParam.status,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.g-el-form {
    padding: 0 100px;
}
</style>
