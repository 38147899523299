<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            :model="ajaxParam"
            :rules="rules"
            ref="refElForm"
            label-width="120px"
        >
            <el-form-item
                :label="`${'所属公司'}:`"
                prop="companyId"
                v-if="$admin"
            >
                <GlobalCompanySelect
                    v-model="ajaxParam.companyId"
                    @change="ajaxParam.carId = ''"
                />
            </el-form-item>
            <el-form-item :label="`${'车牌号'}:`" prop="carId">
                <GlobalCarSelect
                    :companyId="ajaxParam.companyId"
                    v-model="ajaxParam.carId"
                    :multiple="false"
                ></GlobalCarSelect>
            </el-form-item>
            <el-form-item :label="`${'维修类型'}:`" prop="maintenanceType">
                <GlobalDictSelect
                    v-model="ajaxParam.maintenanceType"
                    parentCode="MAINTENANCE_TYPE"
                    placeholder="请选择维修类型"
                />
            </el-form-item>
            <el-form-item :label="`${'承修单位'}:`" prop="maintenanceUnit">
                <el-input
                    placeholder="请选择承修单位"
                    v-model="ajaxParam.maintenanceUnit"
                ></el-input>
            </el-form-item>
            <el-form-item :label="`${'进厂时间'}:`" prop="arrivalTime">
                <GlobalElDatePicker
                    v-model="ajaxParam.arrivalTime"
                    placeholder="请选择进厂时间"
                ></GlobalElDatePicker>
            </el-form-item>
            <el-form-item :label="`${'出厂时间'}:`" prop="deliveryTime">
                <GlobalElDatePicker
                    v-model="ajaxParam.deliveryTime"
                    placeholder="请选择出厂时间"
                ></GlobalElDatePicker>
            </el-form-item>
            <el-form-item :label="`${'行驶里程'}:`" prop="drivingMileage">
                <el-input
                    placeholder="请选择行驶里程"
                    v-model="ajaxParam.drivingMileage"
                >
                    <div slot="suffix">km</div>
                </el-input>
            </el-form-item>
            <div
                v-if="
                    Array.isArray(ajaxParam.carMaintenanceContentDtos) &&
                    ajaxParam.carMaintenanceContentDtos.length > 0
                "
            >
                <div
                    v-for="(item, index) in ajaxParam.carMaintenanceContentDtos"
                    :key="index"
                    class="car-content-box"
                >
                    <el-form-item
                        :label="`${'维修项目'}:`"
                        :rules="rules.maintenanceProject"
                        :key="index"
                        :prop="
                            'carMaintenanceContentDtos.' +
                            index +
                            '.maintenanceProject'
                        "
                    >
                        <GlobalDictSelect
                            v-model="item.maintenanceProject"
                            parentCode="MAINTENANCE_PROJECT"
                            placeholder="请选择维修项目"
                        />
                    </el-form-item>
                    <el-form-item
                        :label="`${'维修费用'}:(元)`"
                        :prop="
                            'carMaintenanceContentDtos.' +
                            index +
                            '.maintenanceCosts'
                        "
                    >
                        <el-input
                            placeholder="请输入维修费用"
                            v-model="item.maintenanceCosts"
                        >
                            <div slot="suffix">元</div>
                        </el-input>
                    </el-form-item>
                    <el-form-item
                        :label="`${'状况'}:`"
                        :rules="rules.state"
                        :prop="'carMaintenanceContentDtos.' + index + '.state'"
                    >
                        <el-input
                            placeholder="请输入状况"
                            v-model="item.state"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        :label="`${'上传附件'}:`"
                        placeholder="请上传附件"
                        :prop="
                            'carMaintenanceContentDtos.' + index + '.fileVos'
                        "
                    >
                        <GlobalElFileUpload
                            v-model="item.fileVos"
                        ></GlobalElFileUpload>
                    </el-form-item>

                    <div class="del-btn" v-if="index != 0">
                        <i
                            class="el-icon-delete-solid"
                            style="font-size: 28px; color: red"
                            @click="onDelete(index)"
                        ></i>
                    </div>
                </div>
            </div>

            <el-form-item style="text-align: right">
                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-plus"
                    v-if="
                        Array.isArray(ajaxParam.carMaintenanceContentDtos) &&
                        ajaxParam.carMaintenanceContentDtos.length < 5
                    "
                    @click="onAdd"
                    >新增维修项目</el-button
                >
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="onConfirm">确定</el-button>
            <el-button type="info" @click="onCancel">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { rules } from "./rules";
export default {
    computed: {
        rules() {
            return rules;
        },
    },
    title: "操作数据",
    name: "OperatData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },

    data() {
        return {
            ajaxParam: {
                companyId: this.$admin ? "" : this.$companyId, // 所属公司
                carId: "", // 车辆Id
                maintenanceType: "", // 维修类型
                maintenanceUnit: "", // 维修单位（最长50）
                arrivalTime: "", // 进厂时间
                deliveryTime: "", // 出厂时间
                drivingMileage: "", // 行驶里程（最大9999999）
                carMaintenanceContentDtos: [
                    {
                        maintenanceProject: "", // 维修项目
                        maintenanceCosts: "", // 维修费用
                        state: "", // 状况
                        fileVos: [],
                    },
                ],
            },
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        onConfirm() {
            this.$refs.refElForm.validate((valid) => {
                if (valid) {
                    this.submitData();
                }
            });
        },
        onAdd() {
            if (this.ajaxParam.carMaintenanceContentDtos.length >= 5) {
                this.$message({
                    message: "最多允许添加5条数据",
                    type: "warning",
                    center: true,
                });
                return;
            }
            const arr = [
                {
                    id: "",
                    maintenanceProject: "", // 维修项目
                    maintenanceCosts: "", // 维修费用
                    state: "", // 状况
                    fileVos: [],
                },
            ];
            this.ajaxParam.carMaintenanceContentDtos = [
                ...this.ajaxParam.carMaintenanceContentDtos,
                ...arr,
            ];
        },
        onDelete(index) {
            const arr = JSON.parse(
                JSON.stringify(this.ajaxParam.carMaintenanceContentDtos)
            );
            arr.splice(index, 1);
            this.ajaxParam.carMaintenanceContentDtos = arr;
            console.log(index);
        },
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/carMaintenanceRecords/${this.op.id}`
            );
            if (code == 200) {
                Object.keys(this.ajaxParam).forEach((key) => {
                    this.ajaxParam[key] = data[key];
                });

                this.ajaxParam.carMaintenanceContentDtos = [];
                Array.isArray(data.carMaintenanceContentVos) &&
                    data.carMaintenanceContentVos.forEach((item) => {
                        const fileVos = item.fileVos.map((file) => {
                            return Object.assign({ name: file.fileName }, file);
                        });
                        item.fileVos = fileVos;
                        this.ajaxParam.carMaintenanceContentDtos.push(item);
                    });
            }
        },
        async submitData() {
            const ajaxParam = JSON.parse(JSON.stringify(this.ajaxParam));
            const carMaintenanceContentDtos = ajaxParam.carMaintenanceContentDtos.map(
                (item) => {
                    if (Array.isArray(item.fileVos)) {
                        const filePaths = item.fileVos.map(
                            (file) => file.filePath
                        );
                        delete item.fileVos;
                        return Object.assign({ filePaths: filePaths }, item);
                    }
                    delete item.fileVos;
                    return Object.assign({ filePaths: [] }, item);
                }
            );

            ajaxParam.carMaintenanceContentDtos = carMaintenanceContentDtos;

            // 提交(编辑)数据
            let { code } =
                this.op.id == "0"
                    ? await this.$api.post(
                          "/car/server/escort/web/carMaintenanceRecords",
                          ajaxParam
                      )
                    : await this.$api.put(
                          `/car/server/escort/web/carMaintenanceRecords/${this.op.id}`,
                          ajaxParam
                      );
            if (code == 200) {
                this.$message({
                    message: "操作成功",
                    type: "success",
                    center: true,
                });
                this.$emit("query");
                this.onCancel();
            }
        },
        onCancel() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.g-el-form {
    padding: 0 100px;

    .el-select {
        width: 100%;
    }
    .el-date-editor {
        width: 100%;
    }
}

.car-content-box {
    position: relative;
}

.del-btn {
    position: absolute;
    right: -40px;
    top: 5px;
}
</style>
