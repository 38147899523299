<template>
    <el-dialog
        center
        v-bind="$attrs"
        v-on="$listeners"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
    >
        <el-form
            class="g-el-form"
            :model="ajaxParam"
            :rules="rules"
            ref="refElForm"
            label-width="120px"
        >
            <el-form-item
                :label="`${'所属公司'}:`"
                placeholder="请选择公司"
                v-if="$admin"
                prop="companyId"
            >
                <GlobalCompanySelect v-model="ajaxParam.companyId" />
            </el-form-item>
            <el-form-item
                :label="`${'计划名称'}:`"
                placeholder="请输入计划名称"
                prop="planName"
            >
                <el-input v-model="ajaxParam.planName"></el-input>
            </el-form-item>
            <el-form-item
                :label="`${'维修时间'}:`"
                placeholder="请选择维修时间"
                prop="maintenanceTime"
            >
                <GlobalElDatePicker
                    v-model="ajaxParam.maintenanceTime"
                    placeholder="请选择更换日期"
                ></GlobalElDatePicker>
            </el-form-item>
            <el-form-item
                :label="`${'维修车辆'}:`"
                placeholder="请选择维修车辆"
                prop="carNums"
            >
                <GlobalCarSelect
                    v-model="ajaxParam.carNums"
                    :companyId="ajaxParam.companyId"
                    :multiple="true"
                    :isCardId="false"
                />
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="onConfirm">确定</el-button>
            <el-button type="info" @click="onCancel">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { rules } from "./rules";
export default {
    computed: {
        rules() {
            return rules;
        },
    },
    title: "操作数据",
    name: "OperatData",
    props: {
        op: {
            type: Object,
            default: () => {
                return {
                    id: "0",
                    // show: false,
                    // row: null,
                    code: "init",
                };
            },
        },
    },

    data() {
        return {
            ajaxParam: {
                companyId: this.$admin ? "" : this.$companyId, // 所属公司
                planName: "", // 计划名称
                maintenanceTime: "", // 维修时间
                carNums: [], // 车牌号码用逗号隔开
            },
        };
    },
    created() {
        if (this.op.id != "0") this.lookfor();
    },
    methods: {
        onConfirm() {
            this.$refs.refElForm.validate((valid) => {
                if (valid) {
                    this.submitData();
                }
            });
        },
        async lookfor() {
            // 查看详情
            let { code, data } = await this.$api.get(
                `/car/server/escort/web/carMaintenancePlan/${this.op.id}`
            );
            if (code == 200) {
                Object.keys(this.ajaxParam).forEach((key) => {
                    this.ajaxParam[key] = data[key];
                });
                this.ajaxParam.carNums = this.ajaxParam.carNums.split(",");
            }
        },
        async submitData() {
            const ajaxParam = JSON.parse(JSON.stringify(this.ajaxParam));
            ajaxParam.carNums = this.ajaxParam.carNums.toString();
            // 提交(编辑)数据
            let { code } =
                this.op.id == "0"
                    ? await this.$api.post(
                          "/car/server/escort/web/carMaintenancePlan",
                          null,
                          {
                              params: ajaxParam,
                          }
                      )
                    : await this.$api.put(
                          `/car/server/escort/web/carMaintenancePlan/${this.op.id}`,
                          null,
                          {
                              params: ajaxParam,
                          }
                      );
            if (code == 200) {
                this.$message({
                    message: "操作成功",
                    type: "success",
                    center: true,
                });
                this.$emit("query");
                this.onCancel();
            }
        },
        onCancel() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.g-el-form {
    padding: 0 100px;
    .el-select {
        width: 100%;
    }
    .el-date-editor {
        width: 100%;
    }
}
</style>
